<template>
  <b-container>
    <b-icon
      icon="arrow-clockwise"
      animation="spin"
      font-scale="4"
      v-if="isBussy"
    ></b-icon>
    <b-container v-else>
      <b-row>
        <b-col>
          <h4>Exportar Datos</h4>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="text-left">
          <label><b>Sede</b></label>
          <b-form-select
            v-model="placeSel"
            :options="placesOpt"
            placeholder="Seleccione una sede"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <label for="example-datepicker-desde"><b>Fecha Desde</b></label>
          <b-form-datepicker
            id="example-datepicker-desde"
            class="mb-2"
            placeholder="Seleccione una fecha"
            v-model="dateIniSel"
          ></b-form-datepicker>
        </b-col>
        <b-col class="text-left">
          <label for="example-datepicker-hasta"><b>Fecha Hasta</b></label>
          <b-form-datepicker
            id="example-datepicker-hasta"
            class="mb-2"
            placeholder="Seleccione una fecha"
            v-model="dateEndSel"
          ></b-form-datepicker>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-button variant="primary" @click="exportar()"
            ><b-icon icon="cloud-download-fill"></b-icon> Descargar en
            Excel</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import xlsx from "xlsx";
export default {
  data() {
    return {
      dateSel: null,
      isBussy: false,
      placesOpt: [],
      placeSel: null,
      isBussy: true,
      dateIniSel: null,
      dateEndSel: null,
    };
  },
  mounted() {
    if (!localStorage.getItem("emailLogged")) {
      this.$router.replace("/login");
    }
    this.setLayout("AdminLayout");
    this.getPlacesAvailables();
  },
  computed: {
    ...mapState(["userLogged"]),
    ...mapState("reserves", ["reservesByDate"]),
    ...mapState("places", ["places"]),
  },
  methods: {
    ...mapActions(["setLayout"]),
    ...mapActions("reserves", [
      "getReservesByDate",
      "getReservesByDatePrincipal",
      "setPlaceSelected",
      "getReservesExport",
    ]),
    ...mapActions("places", ["getPlaces"]),
    async getPlacesAvailables() {
      await this.getPlaces();
      this.placesOpt.push({
        value: null,
        text: "Todas",
      });
      this.places.forEach((element) => {
        this.placesOpt.push({
          value: element.name,
          text: element.name + " => " + element.adress,
        });
      });
      this.isBussy = false;
    },
    getIdPlace(namePlace) {
      const place = this.places.filter((el) => {
        if (el.name == namePlace) {
          return el;
        }
      });
      return place[0].id;
    },
    exportar() {
      if (!this.dateIniSel || !this.dateEndSel) {
        this.$toastr.e("Debe seleccionar un rango de fechas", "Exportar excel");
        return;
      }

      if (this.dateIniSel > this.dateEndSel) {
        this.$toastr.e(
          "Las fechas seleccionadas no son válidas",
          "Exportar excel"
        );
        return;
      }
      this.getReservesExport({
        dateIni: this.dateIniSel,
        dateEnd: this.dateEndSel,
        place: this.placeSel ? this.getIdPlace(this.placeSel) : null,
      }).then((res) => {
        let tmpData = res.map((el) => {
          let nombreSede = this.places.find((e) => e.id == el.sede);
          return {
            ...el,
            nombre: el.nombre.toUpperCase(),
            fecha: moment(el.fecha).format("DD/MM/YYYY"),
            sede: nombreSede ? nombreSede["name"] : '',
          };
        });
        let data = xlsx.utils.json_to_sheet(tmpData);
        const workbook = xlsx.utils.book_new();
        const filename = `agenda_${moment(this.dateIniSel).format(
          "DDMMYYYY"
        )}_${moment(this.dateEndSel).format("DDMMYYYY")}`;
        xlsx.utils.book_append_sheet(workbook, data, filename);
        xlsx.writeFile(workbook, `${filename}.xlsx`);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
